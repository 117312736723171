.Wallet {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 122px);
}

.Wallet .Balance {
    width: 100%;
    height: 116px;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    position: relative; /* Balance öğesini göreceli konuma getir */
    background-position: center;
    flex-shrink: 0;
}

.Wallet .Transactions {
    width: 100%;
    overflow-x: scroll;
}

.Wallet .TransactionItemContainer {
    width: 100%;
    border-radius: 20px;
    position: relative; /* Balance öğesini göreceli konuma getir */
}


.Wallet .TransactionItem {
    margin-top: 2px;
}

.Wallet .TransactionItemContainer .TransactionItemContent {
    display: flex;
}

.Wallet .TransactionItemContainer .TransactionMiddleSection {
    padding-left: 7px;
}

.Wallet .TransactionItemContainer .TransactionMiddleSection p:first-child {
    font-size: 14px;
    font-weight: 500;
    line-height: 1px;
}

.Wallet .TransactionItemContainer .TransactionMiddleSection p:last-child {
    font-size: 12px;
    font-weight: var(--tg-secondary-text);
    opacity: 0.4;
}

.Wallet .TransactionItemContainer .TransactionEndSection {
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    line-height: 1px;
    right: 5px;
}

.Wallet .TransactionEndSection.NegativeTransaction {
    color: #C91818;
}

.Wallet .TransactionEndSection.PositiveTransaction {
    color: var(--tg-primary);
}

.Wallet .TransactionItemContent .TransactionIcon {
    margin: 5px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
}

.Wallet .TransactionIcon.NegativeTransaction {
    background-color: #F4F4F4;
}

.Wallet .TransactionIcon.PositiveTransaction {
    background-color: var(--tg-soft-green);
}

.Wallet .Balance .BalanceWalletIcon {
    padding-top: 30px;
    padding-left: 10px;
}

.Wallet .Balance .BalanceAmount {
    position: absolute;
    top: 55px;
    right: 30px;
    font-size: 26px;
    font-weight: bold;
    color: var(--tg-light-green);
}

.Wallet .Balance .BuyEnergy {
    position: absolute;
    top: 55px;
    right: 30px;
    font-size: 20px;
    font-weight: bold;
    color: var(--tg-light-green);
}


.Wallet .Balance .BalanceAmount p {
    line-height: 1px;
}

.Wallet .Balance .BalanceAmount p:first-child {
    font-weight: normal;
    color: var(--tg-white);
    font-size: 14px;
    text-align: right;
}

.Wallet .AddBalanceButton {
    color: var(--tg-white);
    background-color: var(--tg-dark);
    border-radius: 23px;
    font-weight: bolder;
    margin-top: 30px;
    height: 46px;
    width: 50%;
    flex-shrink: 0;
}

.Wallet .TransactionItemSeperator {
    width: 100%;
    height: 1px;
    background-color: var(--tg-gray-4)
}


.Wallet .PaymentMethods, .Wallet .SelectAmounts {
    margin-top: -5px;
    width: 100%;
}

.Wallet .ContainerTitle {
    padding-left: 5px;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.Wallet .ContainerSubTitle {
    padding-left: 5px;
    font-size: 14px;
}


.Wallet .PaymentMethodsContainer {
    width: 100%;
    height: 80px;
    border-radius: 12px;
    border: 1px solid var(--tg-gray-4);
    display: flex;
}


.Wallet .PaymentMethodsContainer.SelectedCard {
    background-color: var(--tg-soft-purple);
    border: 1px solid var(--tg-purple);
}

.Wallet .AmountToBePaidLabel{
    font-size:12px;
    padding-top:8px;
    font-weight:400
}

.Wallet .AmountToBePaid{
    margin-top:-10px;
    text-align:left;
    font-size:24px;
    font-weight:600;
}

.Wallet .PaymentMethodsContainer.NoCard {
    padding: 15px 0 0 20px;
    display: flex;
}

.Wallet .PaymentMethodsContainer .AddCard {
    position: absolute;
    right: 30px;
    top: 80px;
}

.Wallet .PaymentMethodsContainerStart {
    padding: 25px 20px 0 20px;
}

.Wallet .PaymentMethodsContainerMiddle {
    line-height: 7px;
    margin-top: 10px;
}

.Wallet .PaymentMethodsContainerMiddle p:first-child {
    font-size: 16px;
    font-weight: 600;
    color: var(--tg-dark);
}

.Wallet .PaymentMethodsContainerMiddle p:last-child {
    font-size: 12px;
    color: var(--tg-solid-gray);
}

.Wallet .PaymentMethodsContainerEnd {
    position: absolute;
    right: 10px;
    top: 85px;
}
.Packages .swiper{
    padding-right: 30px!important;
}

.Wallet .SelectAmounts .Packages {
    display: flex;
    flex-wrap: wrap;
}

.Wallet .SelectAmounts .Package {
    background-color: var(--tg-white);
    border: 1px solid var(--tg-gray-4);
    height: 39px;
    width: 30%;
    border-radius: 10px;
    font-size: 14px;
    color: var(--tg-gray-2);
    margin: 5px 5px 5px 5px;
    text-align: center;
    padding: 10px 0;
    font-weight: 500;
}


.Wallet .SelectAmounts .Package.active {
    color: var(--tg-white);
    font-weight: 700;
    background-color: var(--tg-purple);
    border: 1px solid var(--tg-purple);
}


.Wallet .SelectAmounts .FreeAmountInput {
    width: 100%;
    background-color: #E9EAEA;
    height: 42px;
    border-radius: 10px;
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #E9EAEA;
    font-size: 14px;
}

.Wallet .SelectAmounts .FreeAmountInput::-webkit-input-placeholder {
    font-size: 12px;
}

.Wallet .SelectAmounts .FreeAmountInput::-moz-placeholder {
    font-size: 12px;
}

.Wallet .SelectAmounts .FreeAmountInput:focus {
    outline: none;
    border-color: var(--tg-charge);
    box-shadow: 0 0 3px var(--tg-charge);
}


.Wallet .AddBalanceConfirmContainer {
    display: flex;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    padding: 10px;
}

.Wallet .ChangePaymentMethodButton{
    font-weight:600;
    width:80px;
    height:33px;
    border-radius:20px;
    background-color:#191E26;
    color:white;
}

.Wallet .AddBalanceConfirmButton.disabled {
    background-color: var(--tg-item-gray);
}

.Wallet .AddBalanceConfirmButton {
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:16px;
    color: var(--tg-white);
    background-color: var(--tg-charge);
    border-radius: 25px;
    font-weight: bolder;
    margin-top: 20px;
    height: 46px;
    margin-left: auto;
    width: 45%;
}

.Wallet .PackageDetailContainer {
    border: 2px dashed #8716F7;
    border-radius: 12px;
    height: 242px;
    margin: 15px;
}

.Wallet .PackageDetail .PackagePrice {
    background-color: var(--tg-purple);
    border-radius: 10px;
    width: fit-content;
    padding: 0 15px;
    height: 39px;
    margin: auto;
    text-align: center;
    color: var(--tg-white);
    font-weight: 500;
    font-size: 16px;

}


.Wallet .PackageDetail .DiscountedPrice {
    color: var(--tg-secondary-text);
    font-size: 20px;
    font-weight: 500;
    margin-left: 5px;
}

.Wallet .GraySeperator {
    background-color: var(--tg-gray-line);
    width: 0.5px;
    height: 43px;
}

.Wallet .IntroductionIconContainer {
    margin-top: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.Wallet .IntroductionIcon {
    padding: 0 25px 0 25px;
    text-align: center;
    font-size: 13px;
}

.WalletPopupContainer {
    z-index: 9999;
    position: absolute;
    background-color: rgb(0,0,0,20%);
    height: 100%;
    width: 100%;
    padding: 10px;
}

.WalletPopup {
    height: 80%;
    width: 100%;
    background-color: white;
    margin-top: 20%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
}

.WalletPopup .PopupMessage {
    text-align: center;
    margin-top: -10px;
    font-size: 14px;
}

.WalletPopup .GoToWallet{
    width: 80%;
    height: 50px;
    border-radius: 25px;
    background-color: var(--tg-dark);
    color: white;
    font-weight: 600;
    margin-top: 30px
}

/*width: "100%",
                        backgroundColor: "#fff",
                        position: "fixed",
                        bottom: 0,
                        padding: "13px 30px 30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px .5px 6px rgba(0, 0, 0, 0.16)",
                        gap: 15
                        */
.Wallet .WalletInfoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 13px 30px 30px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
    animation: firstTimeSlideUp 0.5s ease;
}

.Wallet .WalletInfoContainer .WalletInfoTextContainer {
    flex: 1;
}

.Wallet .WalletInfoContainer .WalletInfoTextContainer > div {
    font-size: 14px;
    font-weight: 500;
    color: #191E26;
    margin-bottom: 8px;
}

.Wallet .WalletInfoContainer .WalletInfoTextContainer > span {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #191E26;
    padding: 10px 16px;
    border: 1px solid #939598;
    border-radius: 25px;
}

.Wallet .WalletPrivacyAndPolicy {
    font-size: 12px;
    font-weight: 400;
    color: #939598;
    margin-top: auto;
    padding-bottom: 32px;
    padding-top: 10px;
}
.Wallet .WalletPrivacyAndPolicy b {
    color: #166DF7;
    font-weight: 600;
}



@keyframes firstTimeSlideUp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}