.corporates {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 1rem;
}

.companies-list-item-wrapper {
    border: 1px solid var(--tg-inactive);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    display: flex;
    position: relative;
}

.companies-list-item-wrapper > div:first-child {
    width: 90%;
}
.companies-list-item-wrapper .companyDefaultButton {
    text-align: right;
    width: 10%;
}

.companies-list-item-wrapper .dropdown {
    position: absolute;
    right: 15px;
    top: 30px;
    text-align: left;
    background: #fff;
    border: 1px solid var(--tg-inactive);
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: none;
}

.companies-list-item-wrapper .dropdown p {
    margin: 0;
    padding: 5px 0;
}

.companies-list-item-wrapper .dropdown.active {
    display: block;
}


.companies-list-item-wrapper .companyDefaultButton .moreButton {
    text-align: right;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--tg-error);
    line-height: 0;
}

.companies-list-item {
    display: flex;
    flex-direction: column;
    gap: 3px;
}


.companies-list-item p {
    width: 100% !important;
}

.companies-list-item:first-child {
    margin-bottom: 10px;
}

.companies-list {
    flex: 1 1 100%;
}

.companies-list__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.companies-buttons-wrapper {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}