.InvoicesPage{
    --padding-end: 0px;
}
.InvoiceList {
    height: 100%;
}

.InvoiceList .ActiveOccupationText {
    font-size: 15px;
    margin: 0 16px 12px 15px;
    border-radius: 8px;
    background-color: var(--tg-error);
}

.InvoiceList .ActiveOccupationText p {
    color: white;
    padding: 10px 20px;
    text-align: center;
}

.InvoiceList .PayAllTextWrapper {
    background-color: black;
    font-size: 15px;
    margin: 0 16px 12px 15px;
    border-radius: 8px;
}

.InvoiceList .PayAllTextWrapper > p {
    color: white;
    padding: 10px;
    text-align: center;
}

.InvoiceList .PayAllButton {
    font-size: 15px;
    height: 35px;
    margin: 0 16px 12px 15px;
    width: calc(100% - 32px);
    border-radius: 8px;
    background-color: var(--tg-primary);
}

.InvoiceItem{
    width: calc(100% - 32px);
    height: 104px;
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(147, 149, 152, 0.3);
    padding: 0 16px;
    margin: 0 16px 12px 16px;
}

.InvoiceItem>div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.InvoiceItem>div>div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 12px;
}
.InvoiceItem>div>div>div{
    display: flex;
    gap: 22px;
}
.InvoiceFiltersModalContent{
    padding-top: 16px;
}
.FiltersModalHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.--invoice{
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(147, 149, 152, 0.3);
    padding: 16px 12px;
}

.--invoice:not(:last-child){
    margin-bottom: 10px;
}

.--invoice .right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.--invoice .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.--invoice .line{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.line:not(:last-child){
    margin-bottom: 18px;
}

.--invoice .line .date{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 11px;
}
.line .total-divider {
    width: 100%;
    border-bottom: 1px dashed var(--tg-inactive-border);
}
.invoice_business {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}